<script setup>
import { useConfig } from '@/composables/useConfig';
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../stores/auth.store";
import { useBettorStore } from "../../stores/bettor.store";

const authStore = useAuthStore();
const bettorStore = useBettorStore();
const { config } = useConfig();
const notifications = computed(() => bettorStore.$state.notifications);
const buildVersion = computed(() => authStore.$state.buildVersion);
const environment = computed(() => authStore.$state.environment);
const userRole = computed(() => authStore.$state.user?.role);
const isAdminOrSuperAdmin = userRole.value === 'admin' || userRole.value === 'superadmin';
const isReportViewer = userRole.value === 'admin' || userRole.value === 'superadmin'|| userRole.value === 'monitoring_user' || userRole.value === 'report_editor' || userRole.value === 'report_viewer';
const route = useRouter();
function logout() {
  authStore.logout();
}

onMounted(() => {
  window.sidebartoggle();
  if (isReportViewer) {
    bettorStore.getPotentialIncidents();
    bettorStore.getNotifications();
  }
});



</script>
<template>
  <!-- sidebar -->
  <div class="shadow" id="sidebar-wrapper">
    <nav class="nav_sidebar">
      <div>
        <div class="nav_list">
          <router-link id="listManagerLink" to="/" v-if="can('list-manager-list')">
            <span
              class="nav_link"
              :class="{ active_sidebar: $route.name == 'home' }"
            >
              <i class="fa fa-users" aria-hidden="true"></i>
              <span class="nav_name">{{ config?.sideBarNavName || 'List Manager' }}</span>
            </span>
          </router-link>

          <router-link
            to="/activity"
            class="nav_link"
            id="flaggedIncidentsLink"
            :class="{ active_sidebar: $route.name == 'activity' }"
            v-if="can('acitivity-list')"
          >
            <i class="fa fa-file-text" aria-hidden="true"></i>
            <span class="nav_name">Alerts
            <span v-if="notifications > 0" class="badge bg-danger ms-1 lh-sm">
              {{ notifications }}
            </span>
          </span>
          </router-link>
          <router-link
            to="/setting"
            class="nav_link"
            id="settingsLink"
            :class="{ active_sidebar: $route.name == 'setting' }"
            v-if="can('settings')"
          >
            <i class="fa fa-cog" aria-hidden="true"></i>
            <span class="nav_name">Settings</span>
          </router-link>
          <router-link to="/" id="signOutLink" class="nav_link" @click="logout">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            <span class="nav_name">Sign Out</span>
          </router-link>
        </div>
      </div>
      <div>

      <div id="buildVersion" class="text-white">Version: <span class="fw-bold text-muted">{{buildVersion}}</span> </div>
      <div id="environment" class="text-white">Environment: <span class="fw-bold text-muted">{{environment}}</span> </div>
      <div class="privacyTerms">
        <button  data-bs-toggle="modal"
                    data-bs-target="#openPrivacyModal" type="button" id="termsButton" class="p-0 text-white btnFocusOff  btn link-underline-primary">Terms & Privacy</button>
      </div>
    </div>
    </nav>

  </div>
  <!-- end sidebar -->
</template>


