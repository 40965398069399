import { createMemoryHistory, createRouter } from "vue-router";

import { useAuthStore } from "./stores/auth.store";
import { useBettorStore } from "./stores/bettor.store";
const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: () => import("./pages/Users/ListPage"),
    meta: {
      layout: "AppLayout",
    },
  },
  {
    path: "/login",
    name: "login",
    layout: null,
    component: () => import("./pages/Login"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: "/verify2fa",
    name: "verify2fa",
    layout: null,
    component: () => import("./pages/Verify2FA"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: "/setting",
    name: "setting",
    layout: null,
    component: () => import("./pages/Settings/ListPage"),
    meta: {
      layout: "AppLayout",
    },
  },
  {
    path: "/activity",
    name: "activity",
    props: true,
    component: () => import("./pages/Activity/ProActivity"),
    meta: {
      layout: "AppLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/activity/proBetInc/:id",
    component: () => import("./pages/Activity/ProBetInc"),
    name: "proBetInc",
    props: true,
    meta: {
      layout: "AppLayout",
      requiresAuth: true,
    },
  },
  {
    path: '/full-match-list',
    name: 'FullMatchList',
    component: () => import('./pages/FullMatchList'),
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  const bettorStore = useBettorStore()
  authStore.verifyActivationKey({ license_key: "" })
 
  if (authRequired && !authStore.user) {
    authStore.returnUrl = to.fullPath;
    next('/login')
  } else {
    if (to.name !== 'proBetInc' && to.name !== 'activity') {
      bettorStore.resetSpecificObject();
      localStorage.setItem('activeTab', 'potInc');
    }
    if(to.name == 'home' && to.path != authStore.user.home_page){
      next(authStore.user.home_page);
    } else {
      next();
    }
  }

});

export default router;
